// extracted by mini-css-extract-plugin
export var active = "pricing-module--active--b7beb";
export var bgBlueBridge = "pricing-module--bg-blue-bridge--49007";
export var bgEllipseTop = "pricing-module--bg-ellipse-top--d62af";
export var hero = "pricing-module--hero--d39a3";
export var inactive = "pricing-module--inactive--d9b42";
export var planCard = "pricing-module--plan-card--b62e3";
export var planContainer = "pricing-module--plan-container--0bf4f";
export var roundedButtonGroup = "pricing-module--rounded-button-group--0051a";
export var tabContainer = "pricing-module--tab-container--002f9";
export var themeBlue = "pricing-module--theme-blue--cc1a9";