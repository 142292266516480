import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import PropTypes from 'prop-types'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import SimpleSlider from 'components/carousel/simple'
import SimpleAccordion from 'components/accordion/simple'
import ZebraTable from 'components/table/zebra'
import Icon from 'components/icon'

import { cdnBrandList } from '/static/data/cdnBrand.static'
import faqList from '/static/data/faq.static'
import {
  servicesComparisonTable,
  streamBasicPlanFeature,
  webComparisonTable,
  webPlanFeature,
  webPlanList
} from '/static/data/pricing.static'

import * as pricingStyles from 'components/pricing.module.css'

const BRAND_SLIDER_SETTINGS = {
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
  speed: 9000,
  autoplaySpeed: 1000,
  cssEase: 'linear'
}
const TAB_STREAM = 'stream'
const TAB_WEB = 'web'
const PLAN_WEB = {
  DEVELOPER: 'developer',
  BUSINESS: 'business',
  ENTERPRISE: 'enterprise'
}

const TabSwitch = ({ activeTab = '', handleClick }) => {
  const { t } = useTranslation()
  const isToggle = activeTab === TAB_WEB ? pricingStyles.active : pricingStyles.inactive

  return (
    <div className={`${pricingStyles.tabContainer} ${isToggle}`}>
      <button onClick={handleClick} value={TAB_STREAM}>
        {t('pricingPage.tabs.name1')}
      </button>
      <button onClick={handleClick} value={TAB_WEB}>
        {t('pricingPage.tabs.name2')}
      </button>
    </div>
  )
}
const WebPlanButtonGroup = ({ activeButton = '', handleClick }) => {
  const { t } = useTranslation()
  const buttonStyle = (button) => {
    return activeButton === button ? pricingStyles.active : ''
  }

  return (
    <div className={pricingStyles.roundedButtonGroup}>
      {webPlanList.map((plan) => {
        const planName = plan.key.toUpperCase()

        return (
          <button
            key={plan.key}
            onClick={handleClick}
            value={PLAN_WEB[planName]}
            className={buttonStyle(PLAN_WEB[planName])}
          >
            {t(plan.name)}
          </button>
        )
      })}
    </div>
  )
}
const WebPlanCard = ({ price, period, link = '' }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="mb-8 text-center">
        {typeof price === 'number' ? (
          <React.Fragment>
            <span className="align-top font-bold text-4xl">$</span>
            <span className="font-bold text-6xl">{price}</span>
          </React.Fragment>
        ) : (
          <span className="font-bold text-4.5xl">{t('pricingPage.webPlan.customPrice')}</span>
        )}
        <span className="pl-1">{t(period)}</span>
      </div>
      <div className="flex justify-center">
        {typeof price === 'number' ? (
          <Link to={link} className="btn btn-sm btn-orange block" style={{ width: 220 }}>
            {t('pricingPage.button.getStart')}
          </Link>
        ) : (
          <Link to={link} className="btn btn-sm btn-blue block" style={{ width: 220 }}>
            {t('pricingPage.button.contactUs')}
          </Link>
        )}
      </div>
    </div>
  )
}

const PricingPage = () => {
  const WEB_COMPARISON_TABLE = webComparisonTable()
  const SERVICE_COMPARISON_TABLE = servicesComparisonTable()

  const { t } = useTranslation()
  const { screenXL } = useBreakpoint()
  const [activeTab, setActiveTab] = useState(TAB_STREAM)
  const [activeWebPlan, setActiveWebPlan] = useState(null)
  const [webTableMap, setWebTableMap] = useState({ all: WEB_COMPARISON_TABLE })
  const [serviceTableMap, setServiceTableMap] = useState({ all: SERVICE_COMPARISON_TABLE })
  const clickTab = (event) => {
    setActiveTab(event.target.value)
  }
  const clickWebPlan = (event) => {
    setActiveWebPlan(event.target.value)
  }
  const tableCellFallback = (row = [], cellIndex = 0) => {
    // find correct value if it is a merged cell
    while (cellIndex > 0 && typeof row[cellIndex] === 'undefined') {
      cellIndex--
    }
    return [row[0], row[cellIndex]]
  }

  useEffect(() => {
    const planIndexMapping = {
      [PLAN_WEB.DEVELOPER]: 1,
      [PLAN_WEB.BUSINESS]: 2,
      [PLAN_WEB.ENTERPRISE]: 3
    }

    if (!webTableMap[activeWebPlan]) {
      setWebTableMap({
        ...webTableMap,
        [activeWebPlan]: WEB_COMPARISON_TABLE.map((row) => tableCellFallback(row, planIndexMapping[activeWebPlan]))
      })
    }
    if (!serviceTableMap[activeWebPlan]) {
      setServiceTableMap({
        ...serviceTableMap,
        [activeWebPlan]: SERVICE_COMPARISON_TABLE.map((row) => tableCellFallback(row, planIndexMapping[activeWebPlan]))
      })
    }
  }, [activeWebPlan])
  useEffect(() => {
    setActiveWebPlan(screenXL ? 'all' : PLAN_WEB.DEVELOPER)
  }, [screenXL])

  if (typeof location !== 'undefined') {
    useEffect(() => {
      const searchQuery = new URLSearchParams(location.search)

      searchQuery.get('tab') === TAB_WEB ? setActiveTab(TAB_WEB) : setActiveTab(TAB_STREAM)
    }, [location.search])
  }

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('pricingPage.meta.title'),
            metaDescription: t('pricingPage.meta.description'),
            metaUrl: 'https://www.mlytics.com/pricing/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section className={`${pricingStyles.hero} ${activeTab === TAB_WEB ? pricingStyles.themeBlue : ''}`}>
          <h1 className="mb-7 text-center text-2.5xl xl:text-5xl">
            <Trans i18nKey="pricingPage.title">
              <span></span>
            </Trans>
          </h1>
          <div className="flex justify-center px-6">
            <TabSwitch activeTab={activeTab} handleClick={clickTab} />
          </div>
        </section>
        {activeTab === TAB_STREAM ? (
          <section className={pricingStyles.bgBlueBridge}>
            <div className="flex flex-col xl:flex-row gap-10 justify-center mt-7 mb-16 mx-4">
              <div className="bg-white px-7 py-8 rounded-xl shadow-lg basis-[400px]">
                <h3 className="pb-5 text-center text-2.5xl">{t('pricingPage.streamBasicPlan.cardTitle')}</h3>
                <div>
                  {streamBasicPlanFeature.map((item) => (
                    <div key={item} className="flex py-1">
                      <Icon className="basis-[32px] py-1 text-gray-700" fontSize="20px" name="check" />
                      <span className="text-gray-700">
                        <Trans i18nKey={item}>
                          <span className="font-bold text-blue-200"></span>
                        </Trans>
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex mt-5">
                  <Link to="/signup/" className="btn btn-orange block grow">
                    {t('pricingPage.button.getStart')}
                  </Link>
                </div>
              </div>
              <div className="bg-white flex flex-col justify-between px-7 xl:py-8 rounded-xl shadow-none xl:shadow-lg xl:basis-[400px]">
                <h3 className="hidden xl:block pb-5 text-center text-2.5xl">
                  {t('pricingPage.streamCustomPlan.cardTitle')}
                </h3>
                <div className="hidden xl:flex justify-center">
                  <StaticImage
                    src="https://static.mlytics.com/images/website/stream_custom_plan.png"
                    alt="stream custom plan"
                    style={{ height: 'auto', width: '180px' }}
                  />
                </div>
                <div className="mt-5">
                  <div className="text-center text-gray-700">
                    <Trans i18nKey="pricingPage.streamCustomPlan.feature1">
                      <span className="font-bold text-blue-200"></span>
                    </Trans>
                  </div>
                  <div className="flex justify-center mt-5">
                    <Link to="/lp/get-a-demo/" className="btn btn-blue block xl:grow">
                      {t('pricingPage.button.contactUs')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        {activeTab === TAB_WEB ? (
          <section className="px-4 xl:px-0 mb-10">
            <div className="xl:hidden mt-7 mx-auto max-w-[680px]">
              <div className="bg-blue-200/10 mb-6 px-3 py-4 rounded-3xl">
                <div className="font-bold mb-4 text-blue-200 text-center text-lg">
                  {t('pricingPage.webPlan.cardTitle')}
                </div>
                <div className="flex justify-center gap-1">
                  <div className="flex items-center">
                    <img
                      src="https://static.mlytics.com/images/website/coupon-m.png"
                      alt="coupon"
                      style={{ width: 132 }}
                    />
                  </div>
                  <div>
                    {webPlanFeature.map((feature) => (
                      <div key={feature} className="text-gray-700">
                        <Trans i18nKey={feature}>
                          <span className="font-bold text-blue-200" />
                        </Trans>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <WebPlanButtonGroup activeButton={activeWebPlan} handleClick={clickWebPlan} />
              <div className="p-8">
                {webPlanList.map((plan) => {
                  return plan.key === activeWebPlan ? (
                    <WebPlanCard key={plan.key} link={plan.link} price={plan.price} period={plan.period} />
                  ) : null
                })}
              </div>
            </div>
            <div className={`${pricingStyles.planContainer} ${pricingStyles.bgEllipseTop}`}>
              <div className="bg-blue-100 inline-flex min-w-[300px] px-8 py-4 rounded-l-xl">
                <div>
                  <div className="font-bold text-white text-2.5xl mb-1">{t('pricingPage.webPlan.cardTitle')}</div>
                  {webPlanFeature.map((feature) => (
                    <div key={feature} className="flex items-center leading-loose text-white">
                      <Icon className="mr-2 text-white" fontSize="20px" name="check" />
                      <span>
                        <Trans i18nKey={feature}>
                          <span className="font-bold" />
                        </Trans>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="bg-white inline-flex" style={{ borderRadius: '32px 32px 0 0' }}>
                {webPlanList.map((plan) => (
                  <div key={plan.key} className={pricingStyles.planCard}>
                    <h3 className="capitalize mb-4 text-center text-lg">{t(plan.name)}</h3>
                    <WebPlanCard link={plan.link} price={plan.price} period={plan.period} />
                  </div>
                ))}
              </div>
            </div>
            <div className="mx-auto py-5 xl:w-[1200px]">
              <h2 className="px-6 text-2.5xl">{t('pricingPage.webTable.title')}</h2>
              <div className="overflow-scroll">
                <ZebraTable list={webTableMap[activeWebPlan]} />
              </div>
            </div>
            <div className="mx-auto py-5 xl:w-[1200px]">
              <h2 className="px-6 text-2.5xl">{t('pricingPage.serviceTable.title')}</h2>
              <div className="overflow-scroll">
                <ZebraTable list={serviceTableMap[activeWebPlan]} />
              </div>
            </div>
          </section>
        ) : null}
        <section className="mx-2 pb-20">
          <div className="container">
            <h2 className="text-center text-primary-500 text-2.5xl xl:text-3xl mb-5">
              {t('pricingPage.section1.title')}
            </h2>
            <div className="grid grid-cols-2 gap-5 justify-items-center xl:hidden">
              {cdnBrandList.map((item) => (
                <img key={item.alt} src={item.src} alt={item.alt} height="85" width="170" />
              ))}
            </div>
            <div className="hidden xl:block">
              <SimpleSlider className="slick-bg-white-gradient-x" settings={BRAND_SLIDER_SETTINGS}>
                {cdnBrandList.map((item) => (
                  <div key={item.alt} className="pr-5">
                    <img src={item.src} alt={item.alt} height="100" width="200" />
                  </div>
                ))}
              </SimpleSlider>
            </div>
          </div>
        </section>
        <section className="bg-blue-300/5 px-2 pt-16 pb-20">
          <div className="container">
            <h2 className="text-center text-primary-500 text-2.5xl xl:text-3xl mb-10">
              {t('pricingPage.section2.title')}
            </h2>
            <div>
              <SimpleAccordion iconStyle="plus" list={faqList} textColor="#3a3a3a" />
            </div>
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}

TabSwitch.propTypes = {
  activeTab: PropTypes.string,
  handleClick: PropTypes.func
}
WebPlanButtonGroup.propTypes = {
  activeButton: PropTypes.string,
  handleClick: PropTypes.func
}
WebPlanCard.propTypes = {
  price: PropTypes.number,
  period: PropTypes.string,
  link: PropTypes.string
}

export default PricingPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
