const faq = [
  {
    title: 'pricingPage.section2.list1.0.title',
    content: 'pricingPage.section2.list1.0.content'
  },
  {
    title: 'pricingPage.section2.list1.1.title',
    content: 'pricingPage.section2.list1.1.content',
    links: ['https://help.mlytics.com/en/knowledge/understanding-mlytics-free-credit']
  },
  {
    title: 'pricingPage.section2.list1.2.title',
    content: 'pricingPage.section2.list1.2.content',
    links: ['https://help.mlytics.com/en/knowledge/understanding-mlytics-free-tier']
  },
  {
    title: 'pricingPage.section2.list1.3.title',
    content: 'pricingPage.section2.list1.3.content',
    links: [
      'https://help.mlytics.com/en/knowledge/understanding-mlytics-free-credit',
      'https://help.mlytics.com/en/knowledge/understanding-mlytics-free-tier'
    ]
  },
  {
    title: 'pricingPage.section2.list1.4.title',
    content: 'pricingPage.section2.list1.4.content',
    links: [
      'https://help.mlytics.com/en/knowledge/understanding-mlytics-free-credit',
      'https://help.mlytics.com/en/knowledge/understanding-mlytics-free-tier'
    ]
  },
  {
    title: 'pricingPage.section2.list1.5.title',
    content: 'pricingPage.section2.list1.5.content',
    links: ['https://help.mlytics.com/en/knowledge/which-cdns-are-available-on-each-plan']
  },
  {
    title: 'pricingPage.section2.list1.6.title',
    content: 'pricingPage.section2.list1.6.content',
    links: ['/pricing']
  },
  {
    title: 'pricingPage.section2.list1.7.title',
    content: 'pricingPage.section2.list1.7.content',
    links: ['/pricing']
  },
  {
    title: 'pricingPage.section2.list1.8.title',
    content: 'pricingPage.section2.list1.8.content'
  },
  {
    title: 'pricingPage.section2.list1.9.title',
    content: 'pricingPage.section2.list1.9.content',
    links: ['https://help.mlytics.com/en/knowledge/how-does-the-credit-limit-work']
  },
  {
    title: 'pricingPage.section2.list1.10.title',
    content: 'pricingPage.section2.list1.10.content',
    links: ['https://help.mlytics.com/en/knowledge/how-to-activate-mlytics-origin-shield']
  },
  {
    title: 'pricingPage.section2.list1.11.title',
    content: 'pricingPage.section2.list1.11.content',
    links: ['https://help.mlytics.com/en/knowledge/account']
  }
]

export default faq
